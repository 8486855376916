var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-col',{attrs:{"sm":24,"md":16}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Crear portada")])]),_c('div',{staticClass:"text item"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{attrs:{"label-position":"top","label-width":"100px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"Tipo"}},[_c('ValidationProvider',{attrs:{"name":"tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"width-100",attrs:{"clearable":"","size":"mini","filterable":"","placeholder":"Tipo"},on:{"change":function($event){return _vm.handleSearch()}},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.optionType),function(itemType){return _c('el-option',{key:itemType.value,attrs:{"label":itemType.label,"value":itemType.value}})}),1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"sección"}},[_c('ValidationProvider',{attrs:{"name":"sección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"width-100",attrs:{"size":"mini","filterable":"","placeholder":"Tipo"},model:{value:(_vm.form.id_reference),callback:function ($$v) {_vm.$set(_vm.form, "id_reference", $$v)},expression:"form.id_reference"}},_vm._l((_vm.listItemsTitle),function(itemTitle){return _c('el-option',{key:itemTitle._id,attrs:{"label":itemTitle.title,"value":itemTitle._id}})}),1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Título","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"titulo","rules":"required|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Imagen"}},[(_vm.form.image)?_c('div',[_c('el-image',{attrs:{"src":_vm.$urlGlobalApi +_vm.form.image}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"placeholder"},slot:"placeholder"},[_vm._v(" Loading"),_c('span',{staticClass:"dot"},[_vm._v("...")])])])],1):_vm._e(),_c('el-upload',{attrs:{"drag":"","action":"","name":"image","http-request":_vm.postImage,"on-success":_vm.handleAvatarSuccess,"multiple":""}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("Suelta tu foto aquí o "),_c('em',[_vm._v("haz clic para cargar")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Solo archivos jpg/png con un tamaño menor de 500kb sugerido (1065 x 755)")])])],1),_c('el-form-item',{attrs:{"label":"Publicar"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","disabled":invalid,"icon":"el-icon-upload2"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onStore($event)}}},[_vm._v(" Crear")])],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }